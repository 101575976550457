import axios from "axios";
import { Observable } from "rxjs";
import { AuthService } from "../auth";
import { ArtworkStatusData } from "./models";
export class ArtworksService {
  static getArtworksStatus(): Observable<any> {
    return new Observable((subscriber) => {
      axios
        .get(
          `${process.env.REACT_APP_NFC_SERVICE_URL}/drop/rachel-dejoode-artworks-status`,
          // `${url}/drop/rachel-dejoode-artworks-status`,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            subscriber.next(resp.data as ArtworkStatusData);
            subscriber.complete();
          } else {
            subscriber.error(resp.data.message);
            subscriber.complete();
          }
        })
        .catch((error) => {
          subscriber.error(error);
          subscriber.complete();
        });
    });
  }

  static changeArtworkStatus(animated: boolean): Observable<any> {
    return new Observable((subscriber) => {
      axios
        .post(
          `${process.env.REACT_APP_NFC_SERVICE_URL}/rachel-dejoode-artwork-status`,
          // `${url}/rachel-dejoode-artwork-status`,
          { animated },
          {
            headers: {
              "temporary-token": AuthService.getToken(),
              "Content-Type": "application/json",
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            subscriber.next();
            subscriber.complete();
          } else {
            subscriber.error(resp.data.message);
            subscriber.complete();
          }
        })
        .catch((error) => {
          subscriber.error(error);
          subscriber.complete();
        });
    });
  }
}
